import { HeartPulse, Instagram, Twitter, Facebook, Send, BookIcon as TiktokIcon, Youtube, Linkedin } from "lucide-react";
import { Button } from "./Buttons";

const mainNavItems = [
  { name: "About Us", href: "https://app.icarewellbeing.com/about" },
  { name: "FAQ", href: "https://app.icarewellbeing.com/faq" },
  { name: "Refund Policy", href: "https://app.icarewellbeing.com/refund" },
  { name: "Contact", href: "https://app.icarewellbeing.com/contact" },
];

const socialLinks = [
  { name: "Instagram", href: "https://www.instagram.com/icarewellbeing?igsh=bjB4NWVnamsxMmtx", icon: Instagram },
  { name: "Youtube", href: "https://youtube.com/@icarewellbeing?si=5ehFUVLNwJVy3MUz", icon: Youtube },
  { name: "LinkedIn", href: "https://www.linkedin.com/company/icarewellbeing/", icon: Linkedin },
  { name: "Email", href: "mailto:Heal@icarewellbeing.com", icon: Send },
];

export function Footer() {
  return (
    <footer className="bg-gray-50 border-t">
      {/* Crisis Warning Notice */}
      <div className="bg-red-50 border-b border-red-200">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-4">
          <p className="text-center text-red-700 text-sm md:text-base">
            <span className="font-semibold">Don't use this site if in crisis.</span>{' '}
            <a 
              href="https://icarewellbeing.com/crisis-hotline-mental-health-uae/" 
              className="text-blue-600 hover:text-blue-800 underline font-medium"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>{' '}
            if you are in a crisis or any other person may be in danger - the following resources can provide you with immediate help.
          </p>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Logo and Description */}
            <div className="space-y-4">
              <a href="/" className="flex items-center space-x-2">
                <HeartPulse className="h-8 w-8 text-blue-600" />
                <span className="text-xl font-bold">IcareWellBeing</span>
              </a>
              <p className="text-gray-600 max-w-xs">
                Professional online therapy services connecting you with licensed therapists for better mental health and wellbeing.
              </p>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
                Quick Links
              </h3>
              <ul className="space-y-3">
                {mainNavItems.map((item) => (
                  <li key={item.href}>
                    <a
                      href={item.href}
                      className="text-base text-gray-600 hover:text-blue-600 transition-colors"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
                <li>
                  <a
                    href="https://app.icarewellbeing.com/book-appointment"
                    className="text-base text-blue-600 hover:text-blue-700 font-medium"
                  >
                    Book Appointment
                  </a>
                </li>
              </ul>
            </div>

            {/* Connect */}
            <div>
              <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
                Connect With Us
              </h3>
              <div className="flex space-x-4">
                {socialLinks.map((social) => (
                  <Button
                    key={social.name}
                    variant="ghost"
                    size="icon"
                    asChild
                    className="hover:bg-blue-100 hover:text-blue-600 transition-colors bg-blue-50"
                  >
                    <a
                      href={social.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={social.name}
                    >
                      <social.icon className="h-5 w-5" />
                    </a>
                  </Button>
                ))}
              </div>
              <div className="mt-6">
                <h4 className="text-sm font-medium text-gray-900 mb-2">
                  Working Hours
                </h4>
                <p className="text-gray-600">
                  Monday - Friday: 9:00 AM - 8:00 PM<br />
                  Saturday: 10:00 AM - 6:00 PM<br />
                  Sunday: Closed
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-200 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-500 text-sm">
              © {new Date().getFullYear()} IcareWellBeing. All rights reserved.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a
                href="https://app.icarewellbeing.com/refund"
                className="text-sm text-gray-500 hover:text-gray-900"
              >
                Privacy Policy
              </a>
              <a
                href="https://app.icarewellbeing.com/refund"
                className="text-sm text-gray-500 hover:text-gray-900"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}